.imgPackageDetails {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: 25% 25%;
  margin-top: -9px;
}
.btnMargin{
  margin-left: 3%;
    margin-right: 10%;
}
.secondCardIpt {
  padding-left: 10px;
  width: 35%;
  border-left: 2px solid #655a4e !important;
  margin-top: 4px;
  height: 27px;
}

.firstCardIpt {
  padding-left: 12px;
  width: 64.5%;
}

.imgPackage {
  /* height: 275px; */
  width: 90%;
}

.hdGkUx {
  position: absolute !important;
  bottom: 0px;
}
div.sc-bczRLJ{
  width: inherit;
}
.sc-gsnTZi.lgjCTq.ClassError {
  height: 45px;
}

div.sc-bczRLJ.dIRWMy>div.sc-gsnTZi.lgjCTq.ClassError>label>input#cvc {
  display: none;
}

div.sc-bczRLJ.dIRWMy>div.sc-gsnTZi.lgjCTq.ClassError>label>input#card-expiry {
  border-left: 2px solid #655a4e;
  padding-left: 12px;
  -webkit-border-radius: 0px;
}

.lgjCTq.is-invalid {
  border: 2px solid #655a4e !important;
}

.inputCard {
  border: none;
  background: #fffbf0;
  padding-top: 7px;
  -webkit-border-radius: 0px;
}

.lgjCTq {
  border: 2px solid #655a4e;
  background: #fffbf0 !important;
}

.lfVwdQ .credit-card-input {
  background-color: #fffbf0;
}

/**/
/* first div sc-bczRLJ kEeNDb */
/* second div  sc-gsnTZi jwWTyK ClassError */

/* label 1 sc-hKMtZM dfGVWB */
.fpWtUs {
  position: absolute;
  bottom: 0px;
}

.sc-gsnTZi.jwWTyK.ClassError {
  height: 45px;
}

div.sc-bczRLJ.kEeNDb>div.sc-gsnTZi.jwWTyK.ClassError>label>input#cvc {
  display: none;
}

div.sc-bczRLJ.kEeNDb>div.sc-gsnTZi.jwWTyK.ClassError>label>input#card-expiry {
  border-left: 2px solid #655a4e;
  padding-left: 12px;
  -webkit-border-radius: 0px;
}

.jwWTyK.is-invalid {
  border: 2px solid #655a4e !important;
}

.jwWTyK {
  border: 2px solid #655a4e;
  background: #fffbf0 !important;
}

.dfGVWB .credit-card-input {
  background-color: #fffbf0;
}

/**/


.payButtonModal {
  height: 50px !important;
  min-height: 50px !important;
  width: 230px !important;
  min-width: 230px !important;
  font-family: Neue !important;
  font-size: 14px !important;
  border: none;
  background: black;
  color: white;
  border-radius: 5px;
}

.languageSpan {
  font-family: NeueMd !important;
}

.priceDivfoot {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-left: -5rem;
}

div.news-p>div>p>b,
div.news-p>div>p>span>b,
div.news-p>div>div>b {
  font-family: NeueMd !important;
}

div.ProductItem-details-excerpt>a {
  color: black !important
}

.packInput {
  padding-left: 25px !important;
  top: 7px;
  left: 3%;
  /* color: #827564; */
}

.imgBackDiv {
  display: flex;
  /* justify-content: center; */
}

@media screen and (max-width: 895px) {
  .imgBackDiv {
    justify-content: left;
  }

  .imgPackage {
    height: 275px;
    width: 90%;
  }
}

@media screen and (max-width: 500px) {

  .gpay-button {
    width: 150px !important;
  }

  .imgPackage {
    height: 200px;
    width: 90%;
  }
}



div.news-p>div>p {
  color: black;
}

div.news-p>div>ul {
  color: black;
}

div.ProductItem-details-excerpt {
  color: black;
}

div.news-p>ul {
  color: black
}

div.news-p>div>ul>p {
  color: black;
}

div.news-p>div>p>a {
  color: black;
}

div.dropdownPackage>button {
  border: 1px solid #827564;
  color: #827564;
  /* background-color: #fffaed; */
  background-color: #fffbf0;
  width: 97%;
}

div.dropdownPackage>button.show {
  border: 1px solid #827564;
  color: #827564;
  background-color: #fffbf0;
}

div.dropdownPackage>button:hover {
  border: 1px solid #827564;
  color: #827564;
  background-color: #fffbf0;
}

div.dropdownPackage.show>.btn-primary.dropdown-toggle {
  border: 1px solid #827564;
  color: #827564;
  /* background-color: #fffaed; */
  background-color: #fffbf0;
}


div.dropdownPackage.show>.btn-check:focus+.btn-primary,
.btn-primary:focus {
  border: 1px solid #827564;
  color: #827564;
  background-color: #F9F5EC;
}

.dropMenu.dropdown-menu.show {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* background-color: #fffaed; */
  background-color: #F9F5EC;
  color: #827564;
  margin-left: 25%;
  width: 45%;
}

.dropItem.dropdown-item {
  border-bottom: 1px solid #827564;
  display: flex;
  justify-content: center;
  padding: 5px;
}

@media screen and (max-width:560px) {
  .dropItem.dropdown-item {
    font-size: 12px;
  }
}

div.dropDetail.dropdown>button {
  border: none;
  color: white;
  /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
  background-color: #655a4e;
  width: 170px;
  text-align: left;

}

@media screen and (max-width: 430px) {


  .widthControl {
    width: 100px !important;
  }
}

@media screen and (max-width: 433px) {
  div.dropDetail.dropdown>button {
    width: auto
  }

  div.dropDetail.show>.btn-primary.dropdown-toggle {
    width: auto !important;
  }
}

/* div.dropDetail.show>.btn-check:focus+.btn-primary,
.btn-primary:focus {
  border: none;
  color: black;
  background-color: #b0a79c82;
  width: 170px;
  text-align: left;
} */

div.dropDetail.show>.btn-primary.dropdown-toggle {
  border: none;
  color: white;
  /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
  background-color: #655a4e;
  width: 170px;
  text-align: left;
}

.priceDrop.dropdown-menu.show {
  /* background-color: #fffaed; */
  color: white;
  /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
  background-color: #655a4e;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0px;
}


p.news-p>a {
  color: #8b7252
}

.selectionPackage {
  width: 95%;
  margin: 0 auto;
  margin-top: 3%;
  box-shadow: none;
  outline: none;
  color: #827564;
  border: 1px solid #827564;
  background-color: #fffaed;
}

.selectionPackage:hover {
  box-shadow: none;
  outline: none;
  border: 1px solid #827564;
}

.selectionPackage:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #827564;
}


.pack {
  border-bottom: 2px solid #ae9c84;
  width: 90%;
  margin-top: 2%;
}

.pack-2 {
  border-bottom: 2px solid #ae9c84;
  width: 85%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.am-div {
  margin-top: 2%;
  /* margin-bottom: 20%; */
  margin-bottom: 160px;
}

.ul-pack {
  list-style-type: none;
  padding: 0 !important;
}

.li-pack {
  float: left;
}

.dropdown-toggle {
  background: #b0a79c82;
  border-radius: 3px;
}

.ipt-pack {
  /* background-color: #b0a79c82; */
  /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
  background: #655a4e;
  color: white !important;
  border: none;
  border-radius: 3px;
  padding: 7px;
  opacity: 1;
  width: 170px;
  -webkit-text-fill-color: white
}



.btn-pack {
  font-size: 12px;
}

.packageHistory {
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 10px 10px 20px
}

.marginHistory {
  margin-left: 7% !important
}

.widthHistory {
  width: 82% !important
}


/* @media screen and (max-width: 501px) {
  .am-div {
    display: flex;
  }
} */

@media screen and (max-width: 1000px) {
  /* .dropMenu.dropdown-menu.show {
    margin-left: 30%;
  } */

  .marginHistory {
    margin-left: 4% !important
  }

  .widthHistory {
    width: 92% !important
  }
}


@media screen and (max-width: 767px) {
  .btnMargin {
    margin-left: 10%;
    margin-right: 11%;
}

  .buttonPriceFoot {
    width: 73% !important;
  }

  .marginHistory {
    margin-left: -3% !important
  }

  .widthHistory {
    width: 100% !important
  }
}