.removeTopBorder{
    border-top: none !important;
    /* max-height: 335px; */
    overflow: scroll;
}
.font15{
    font-size: 15px;
}
.font600{
    font-weight: 600;
}
.marginleft6{
    margin-left: 6px;
}
.pointer{
    cursor: pointer;
}
.btn-close{
    position: absolute;
    top: 12px;
    right: 14px;
}
.btn-close:focus{
    box-shadow: none;
}
.NeueMdFamily{
    font-family: 'NeueMd' !important;
}