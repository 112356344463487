svg.emailIcon>g>path,
svg.emailIcon>g>g>path {
  fill: white !important;
}

div.ques>h2>strong {
  /* letter-spacing: -1px !important; */
  font-weight: bolder !important;
  font-size: 26px;
  /* line-height: 10px; */
}

.ques h3 strong {
  font-size: 18.72px;
}

.btnMultiLinkPersist:hover {
  color: #000000 !important;
  background-color: #F9F6EC !important;
  border: 2px solid black !important
}

div.opt>div>div>h2,
div.opt>div>div>p,
div.opt>div>div>h3 {
  font-style: normal;
  /* line-height: 35px; */
  width: 100%;
}

.ques>ul>li {
  line-height: 25px;
}

@media screen and (max-width:767px) {
  .ques1>ul>li {
    line-height: 22px !important;
  }
}

div.opt>div>div>h2 {
  margin-bottom: 1rem;

}

div.ques>h1 {
  margin-bottom: 1rem;
}

div.ques>h2 {
  margin-bottom: 1rem;
  /* font-size: 2.3rem; */
}

div.ques>h3 {
  margin-bottom: 1rem;
  /* font-size: 1.7rem; */
  font-size: 18.72px;
}

/* div.opt>div>div>p {
  margin-bottom: 3%;
} */

/* div.opt>div>div>h3 {
  margin-bottom: 5%;
} */

svg.btnEmailTab>g>path {
  fill: white !important;
}

div.ques1>h2>strong {
  /* letter-spacing: -1px !important; */
  font-weight: bolder !important;
  font-size: 26px;
  /* line-height: 10px; */
}

.ques1 h3 strong {
  font-size: 18px;
}

div.ques1>p {
  line-height: 24px !important;
}

/* p.sum>p>span {
  font-family: Nueu;
} */

div.opt>div>div>p>a {
  color: black;
}

svg.btnEmailTab>g>g>path {
  fill: white !important;
}

svg.btnEmailTab {
  height: 18px;
  margin-top: -4px;
}

.desclaimCheck {
  color: white !important;
  background: #655a4e;
  /* background-color: #F9F5EC; */
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #655a4e;

}

div.desclaimCheck>input {
  /* border: 2px solid #655a4e; */
  /* background: #655a4e; */
  /* border: 2px solid #827564;
  background: #827564; */
  cursor: pointer;
  border-radius: 2px !important;
  border: 1px solid #F9F5EC;
  margin-left: -1em !important;
  background: #F9F5EC;
}

div.desclaimCheck>label {
  margin-left: 10px;
  opacity: 1;
  /* color: #655a4e !important */
}

.desclaimFoot {
  display: block;
}

.desclaim {
  text-align: left;
}

p.desclaim>h2 {
  font-size: 26px !important;
  font-weight: bolder;
}

p.desclaim>h3 {
  font-size: 26px !important;
}

.btnActionLink {
  /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
  background-color: #655a4e !important;
  color: white !important;
  border: 2px solid transparent !important;
}

.btnActionLink:hover {
  color: #655a4e !important;
  border: 2.5px solid #655a4e !important;
  background: #F9F5EC !important;
  font-family: NeueMd !important;

}

.warn {
  border: 1px solid #707070;
  padding: 4% 1%;
  border-radius: 10px;
  font-size: 11px;
}

div.evaluation-modal>div.modal-dialog>div.modal-content {
  margin-bottom: 20%;
}

.btn.emailBtn {
  /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
  background-color: #655a4e;
  /* background: #e3dcce; */
  border: none;
  color: white;
  height: 50px;
  width: 290px;
  padding: 2% 4%;
  align-items: center;
  justify-content: center;
  display: flex;
  /* text-shadow: 0px -1px 1px #000000; */
}

/* 
.btn.emailBtn:hover {
  background: #c4b6a5;
  border: none;
  color: #685e52;
  padding: 2% 4%;
} */

div.ques>p>a {
  color: black;
}

div.ques1>p>a {
  color: black;
}

.btn.evaluationBtn,
.btn.evaluationBtn:active,
.btn.evaluationBtn:focus {
  background-color: #655a4e !important;
  border: 1px solid #655a4e !important;
  color: white;
  width: 95%;
  font-weight: 600;
  align-items: center;
  padding: 8px 0px;
  border-radius: 10px;
  letter-spacing: 0px !important;
}

.btn.evaluationBtn1,
.btn.evaluationBtn1:active,
.btn.evaluationBtn1:focus {
  background-color: #655a4e !important;
  border: 1px solid #655a4e !important;
  color: white;
  width: 95%;
  font-weight: 600;
  align-items: center;
  padding: 8px 0px;
  border-radius: 10px;
  letter-spacing: -1px !important;
}

.btn.countBtn {
  border-radius: 50%;
  height: 27px;
  width: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: black;
  background: #F9F5EC;
  margin-left: -15px;
  margin-top: -8px;
  border: 1px solid black;
}

.ques {
  font-style: normal;
  /* line-height: 35px; */
  /* width: 80%; */
  margin-left: 5%;
  margin-bottom: 3%;
}

.ques1 {
  font-style: normal;
  line-height: 35px;
  /* width: 80%; */
  margin-left: 5%;
  margin-bottom: 3%;
}

.btn.btn-ques {
  /* background-color: #f9f4e7; */
  background-color: #fffbf0;
  margin-left: 5%;
  border: 2px solid black;
  height: 60px;
  margin-bottom: 1%;
  border-radius: 6px;
  text-align: left;
  color: #000000;
}

.btn.btn-active {
  /* background-color: #887864; */
  background-color: #000000;
  border: 2px solid transparent;
  color: white;
  margin-left: 5%;
  height: 60px;
  margin-bottom: 1%;
  border-radius: 6px;
  text-align: left;
}

.sum {
  border: 2px solid #655a4e;
  width: 100%;
  margin-top: 1rem;
  border-radius: 20px !important;
  margin-right: 3%;
  overflow-y: scroll;
  overflow-x: hidden;

}

.sum1 {
  border: 2px solid #655a4e;
  width: 100%;
  margin-top: 1rem;
  border-radius: 20px !important;
  margin-right: 3%;
  height: 247px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Safari and Chrome */
.sum1::-webkit-scrollbar,
.sum::-webkit-scrollbar {
  display: none;
}

.sum-s {
  /* justify-content: space-evenly; */
  display: flex;
  align-items: flex-start;
  padding-top: 3%;
  margin-bottom: 3%;
  border-radius: 10px;
  margin-left: 5%;
}

.sum-h {
  background-color: #655a4e;
  color: white;
  text-align: center;
  font-family: NeueMd !important;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  word-break: break-word;
  padding: 10px;
  position: sticky;
  top: 0;
  margin-bottom: 5%;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  border: 1px solid #655a4e;
  width: 101%;

}

option {
  color: white !important;
}

.sum-h1 {
  background-color: #655a4e;
  color: white;
  text-align: center;
  font-family: NeueMd !important;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;

  word-break: break-word;
  padding: 10px;
  position: sticky;
  top: 0;
  margin-bottom: 5%;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  width: 101%;
  border: 1px solid #655a4e;

}

.sum-p {
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #685e52;
  padding-left: 4%;
  width: 90%;
}

p.sum-p>p {
  text-align: left !important;
  color: black;
  font-family: Neue !important;
}

p.sum-p>p>span {
  text-align: left !important;
  color: black;
  font-size: 16px !important;
  font-family: Neue !important;
}

/* p.sum-p>p>span {
  font-family: Nueue !important;
} */

.foot {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.btn.btn-foot,
.btn.btn-foot:focus {
  /* color: black;*/
  color: #655a4e;
  margin-left: 10px;
  /* background: #e0d7c8; */
  /* background: #f0e9d9; */
  /* background-color: #F9F5EC; */
  background-color: #fffbf0;
  /* border: 1px solid #707070; */
  border: 2px solid #655a4e;
  border-radius: 13px;
  height: 49px;
  align-items: center;
  width: 100px;
  padding: 5px 15px 5px 15px;
}

@media screen and (min-width:480px) {
  .btn.btn-foot:hover {
    background-color: #655a4e;
    color: white;
    fill: white !important;
  }

  .btn.btn-foot:hover svg.footer-btn-icon>path {
    fill: white !important;
  }


  .btn.btn-foot:hover svg.footer-btn-icon>g>path {
    fill: white !important;
  }

  .btn.btn-ques:hover {
    background-color: #000000;
    border: 2px solid transparent;
    color: white;
  }
}

@media screen and (max-width:480px) {
  .btn.btn-foot:active {
    background-color: #655a4e;
    color: white;
    fill: white !important;
  }

  .btn.btn-foot:active svg.footer-btn-icon>path {
    fill: white !important;
  }

  .btn.btn-ques:active {
    background-color: #000000;
    border: 2px solid transparent;
    color: white;
  }


  .btn.btn-foot:active svg.footer-btn-icon>g>path {
    fill: white !important;
  }
}

.footer-btn-icon>path {
  fill: #655a4e;
}



/* svg.footer-btn-icon>path:hover {
  fill: white !important;
} */

.ques>p {
  margin-left: 0 !important;
  font-size: 16px;
}

.ques1>p {
  margin-left: 0 !important;
}

.btn.evaluationBtn {
  display: none;
}

.btn.evaluationBtn1 {
  display: none;
}

.btn.btnSendEmail,
.btn.btnSendEmail:hover {
  /* background-color: #9f8c74; */
  /* background-color: #827564; */
  background-color: #807566;
  height: 50px;
  border-radius: 50%;
  width: 9%;
  margin-top: 15px;
  margin-right: 3%;
  border: none !important;
  border-color: transparent;
}

svg.shareIcon>g>circle {
  fill: #807566;
}

.shareIcon {
  margin-right: 3%;
  margin-top: 13px;
  cursor: pointer;
}

@media screen and (max-width: 1500px) {

  .btn.btnSendEmail,
  .btn.btnSendEmail:hover {
    height: 45px;
    width: 9%;
  }
}

@media screen and (max-width: 906px) {

  .btn.btnSendEmail,
  .btn.btnSendEmail:hover {
    height: 45px;
    width: 9%;
  }
}

@media screen and (max-width: 768px) {
  .btn.evaluationBtn {
    display: initial;
  }

  .opt {
    margin-bottom: 17% !important;
  }

  .btn.evaluationBtn1 {
    display: initial;
  }

  .foot1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    /* box-shadow: 5px -5px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */
    padding-top: 14px;
    background: #fffbf0;
  }
}

/* @media screen and (min-width: 767px) {
  .btn.btn-ques:hover {
    background-color: #000000;
    border: 2px solid transparent;
    color: white;
  }
} */



@media screen and (max-width: 767px) {
  .btnForMultiLink:hover {
    background-color: transparent !important;
    border: 2px solid black !important;
    color: black !important;
  }
}

@media screen and (max-width: 501px) {
  .btn.countBtn {
    border-radius: 50%;
    height: 27px;
    width: 25px;
    display: initial;
    color: white;
    /* background: #827564; */
    background-color: #F9F5EC;
    color: black;
    margin-left: -15px;
    margin-top: -11px;
    padding: 0px 0px 0px 0px;
    border: 1px solid black;
  }

  .ques {
    width: 90%;
  }

  .ques1 {
    width: 90%;
  }

  .sum-s {
    padding-top: 6%;
    width: 90%;
    /* margin-bottom: 20%; */
    display: block;
  }

  .sum {
    margin-top: 5%;
    width: 100%;
  }

  .btn.btn-ques {
    margin-bottom: 5%;
    height: 100%;
    text-align: left;
  }

  .btn.btn-active {
    margin-bottom: 5%;
    height: 100%;
    text-align: left;
  }

  .opt-s {
    width: 90%;
  }

  /* .opt {
    margin-bottom: 50% !important;
  } */
}

div.mp4>p>a {
  color: black;
}

p.desclaim>p>a {
  color: black;
}

.ques-foo {
  position: relative;
  top: 1px;
  left: 6px;
  font-size: 13px;
  font-family: NeueMd !important;
}




@media screen and (max-width: 469px) {

  .btn.btn-foot,
  .btn.btn-foot:focus {
    min-width: auto;
    width: auto;
  }
}

@media screen and (min-width: 767px) {
  .navi-paragraph {
    padding-left: 20%;
  }

  /* .scrolling {
    height: 247px;
    overflow-y: scroll;
  } */
}

/* @media screen and (min-height: 1000px) {
  .scrolling {
    height: 350px;
    overflow-y: scroll;
  }
}

@media screen and (min-height: 1200px) {
  .scrolling {
    height: 550px;
    overflow-y: scroll;
  }
} */

@media screen and (max-width: 430px) {
  .ques-foo1 {
    display: none !important;
  }

  .btn.btn-foot1 {
    min-width: auto !important;
    width: 50px !important;
    padding: 0px !important;
  }

  .marginReport {
    margin: 0 !important;
  }

  .ques-foo {
    font-size: 13px;
    left: 3px;
  }
}

@media screen and (max-width: 568px) {

  .btn.btn-foot {
    min-width: 64px;
  }
}
/* Evaluation Summary grid Container */
.evaluationContainer{
  /* height: 100%; */
  /* display: grid;
    grid-template: auto / 1fr 36px;
    overflow: hidden;
    position: relative; */
    display: flex;
    position: relative;
}
.gridScrollContainer{
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  /* display: grid;
  gap: 1rem;
  rid-auto-flow: row;
  grid-template: auto 1fr auto / 1fr;
  padding: 1rem;
  place-items: center; */
}
.bottomArrow{
  position: absolute;
    bottom: 7px;
    left: -13px;
}
.arrowRotate180{
  transform: rotate(180deg);
}
.arrowRotate117{
  transform: rotate(240deg);
}