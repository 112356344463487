@media screen and (max-width:768px) {
  .logoMargin {
    display: none !important;
  }

  .nav-menu.active {
    width: 100%;
    height: 80px;
    display: contents;
  }

  .MenuIcon {
    display: none
  }

  .li-Nav {
    float: left !important;
    width: 20%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

  }

  .navbar-toggle {
    display: none;
  }

  .nav-text>a {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }

  .nav-menu-items {
    background: #fffbf0;
    z-index: 1;
    list-style: none;
    position: fixed;
    left: 0;
    bottom: 0;
    margin-bottom: 0px;
    box-shadow: 0px 2px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);

    /*Added for Safari*/
    padding-bottom: 10px;
  }

  .nav-text {
    width: 120px;
    display: contents;
  }



  span.items {
    display: flex;
    margin: 0;
    font-size: 10px;
  }

  /* tab bar causing problems */
  .footer-fix {
    /* bottom: 63px; */
    bottom: 76px;
  }

  .foot1 {
    bottom: 63px;
  }

  .footer {
    background: #fffbf0 !important;
  }

  .sideBarResp {
    margin-bottom: 70px !important;
  }

  .main,
  .news-m,
  .schContainer,
  form.signin-form {
    margin-bottom: 70px;
  }


  /*Active Tab*/
  li.sideactive {
    background-color: #fffbf0;
  }

  li.sideactive>span>a>span>span {
    color: #655a4e !important;
  }

  li.sideactive>span>a>svg>path {
    fill: #655a4e !important;
  }

  /*For Questionnaire Options*/
  .opt.AllNode {
    margin-bottom: 50% !important;
  }
}

@media screen and (min-width: 767px) {
  .mbtm {
    margin-bottom: 20% !important;
  }

  .cont-n {
    padding-left: 3% !important;
  }
}

/*Card Responsiveness*/
@media screen and (max-width: 500px) {
  .lfVwdQ .credit-card-input {
    width: auto !important;
  }

  .packagePd {
    padding-bottom: 25% !important
  }

  .dfGVWB .credit-card-input {
    width: auto !important;
  }

  .firstCardIpt {
    width: 62.5% !important;
  }
}

/*Navigation center*/
@media screen and (max-width: 767px) {
  .dropAbsolute {
    position: absolute;
    top: 0;
    right: 0;
  }

  .backAbsolute {
    position: absolute;
    top: 5;
    left: 0;
  }
}

/*Evaluation Box*/
.EvaluationBox {
  border: 2px solid #655a4e;
  margin-right: 5%;
  border-radius: 6px;
  cursor: pointer;
}

.EvaluationHeading {
  background: #655a4e;
  align-items: center;
  text-align: center;
  padding: 10px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-family: NeueMd !important;
}

.Evaluations {
  overflow-x: scroll;
  padding: 10px;
}

.EvaluationinShare {
  padding: 10px;
  height: auto !important;
}

.Evaluations::-webkit-scrollbar {
  display: none;
}

.IndiEvalutation>p {
  text-align: left !important;
  color: black;
  font-family: Neue !important;
  margin-left: 0px !important;
}

.IndiEvalutation>p>span {
  text-align: left !important;
  color: black;
  font-size: 16px !important;
  font-family: Neue !important;
  margin-left: 0px !important;
}



@media screen and (max-width:767px) {
  .ResponsiveBox {
    display: none;
  }

  .shareIcon {
    display: none;
  }
}