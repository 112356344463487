.labelTitle {
    font-family: NeueMd !important;
}

.headermain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: static;
    background: red;
    top: 0;
}

.content {
    text-align: center;
}

.flex-Ele {

    flex-direction: column;
    width: 20%;
}

.headerDate {
    /* text-shadow: 0px -1px 1px #000000; */
    color: white;
    font-weight: 400;
    font-size: 14px;
}

.table-responsive {
    width: 100% !important;
}

.scroll {
    overflow-x: scroll;
}

.tableItem {
    width: 138px;
    padding: 5px;
}

.marginBottom12 {
    margin-bottom: 12px;
}

.tableHead {
    /* text-shadow: 0px -1px 1px #000000; */
    font-size: 16px;
    font-weight: 700;
}

.tablebody:hover {
    /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
    background-color: #655a4e;
    color: white;
    cursor: pointer;
}

.next {
    cursor: pointer;

    font-size: 14px;
    font-weight: 600;
    /* text-decoration: underline; */
    color: #807566;
}

.selectDate {
    background: #655a4e;
    /* background: transparent; */
    cursor: pointer;
    /* color: #655a4e; */
    color: white;
    border-radius: 2px;
    /* border: 2px solid #655a4e; */
    /* padding: 3px; */

}

/* .selectDate:hover {
    background: transparent;
    cursor: pointer;
    color: #655a4e;
    border-radius: 2px;
    border: 2px solid #655a4e;
    padding: 3px;
} */

.selectTimeZone,
.selectTimeZone:focus {
    background-color: #655a4e;
    outline: none;
    color: white;
    box-shadow: none;
    border: none;
    width: 50%;
    margin-bottom: 1.5rem;
    margin-left: 10px;
    margin: 0 auto;
    /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
}