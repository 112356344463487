div.guestPayment>input.form-check-input{
    background: #d7d0c3;
    border: 1px solid #d7d0c3;
    border-radius: 2px;
}
.form-check-input:focus{
    box-shadow: none;
}
.marginBottom0{
    margin-bottom: 0px !important;
}
.marginBottom20{
    margin-bottom: 20px !important;
}
.guestformGrid{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.formHeight{
    /* height: 630px; */
    height: 750px;
}
.formHeightCheck{
    /* height: 630px; */
    height: 809px;
}
.padding450{
    /* padding-top: 465px  !important; */
    padding-top: 744px  !important
}
.guestForm{
    /* height: 500px;
    overflow-y: scroll;
    padding-top: 402px; */
    /* height: 600px; */
    overflow-y: scroll;
    /* padding-top: 634px; */
    /* padding-top: 654px; */
}
.guestFooter{
    /* 730px */
    position: absolute;
    bottom: 0;
    top: 660px;
    left: 0;
    right: 0;
    padding: 0 19px;
    padding-top: 10px;
    box-shadow: 0 0 10px 1px #d7d0c3;
    /* position: absolute;
    bottom: 0;
    top: 538px;
    left: 0;
    right: 0;
    padding: 0 19px;
    padding-top: 10px;
    box-shadow: 0 0 10px 1px #d7d0c3; */
}
.guestFooterTop{
    top: 743px !important
}

.guestFootShadow{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /* box-shadow: 0 0 10px 1px #d7d0c3; */
    padding: 0 28px;
    padding-bottom: 20px;
    padding-top: 9px;
}