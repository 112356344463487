.imgPlayButton {
  width: 85% !important
}

p.desclaim>h2 {
  margin-bottom: 1rem;
}

div.padding.mp4>h2 {
  margin-bottom: 1rem;
}

div.padding.mp4>p {
  margin-bottom: 1rem;
}
.reviewPadding{
  padding-left: 10px;
    padding-top: 10px;
}
@media screen and (max-width: 700px) {
 .displayNone{
  display: none;
 }
}


img.logo {
  padding-top: 50px;
  background: transparent;
  height: 60%;
  width: 15%;
  object-fit: contain;
}

.logoMargin {
  margin-left: 50px;
  width: 150px;
  margin-top: 25px;
  margin-bottom: 19px;
}

.header {
  display: flex;
  justify-content: center;
  height: 50vh;
  width: auto;
  background-image: url("../../../assets/images/head.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.col-main-9 {
  margin-left: auto;
  width: 77%;
}

.container.container-home {
  display: flex;
  justify-content: center;
}

.btn-img {
  background: transparent;
  margin-bottom: 30px;
}

.btn.btn-home {
  background: #f0e9d9;
  height: 200px;
  width: 330px;
  margin: 15px;
  border-radius: 10px;
}

.btn.btn-home:hover {
  border: 2px solid #aaa18d;
}

span.btn-para {
  background: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
}

.paragraph-home {
  padding-top: 5%;
  padding-left: 10%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.cont_home {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  top: -67px;
  background: transparent;
}

.cont-news {
  display: flex;
}

.descrptn_cont {
  margin-left: 5%;
  width: 100%;
}

.col-his {
  /* background-color: #f0e9d9; */
  background-color: #F9F5EC;
  margin-bottom: 5%;
  width: 48%;
  margin-left: 10px !important;
  display: flex;
  align-items: center;
  border: 1px solid #000000
}

.col-news {
  margin-bottom: 5%;
  /* background: linear-gradient(to bottom, #F9F5EC 5%, #F9F5EC 100%); */
  margin-left: 10px !important;
  display: flex;
  align-items: center;
  color: #655a4e;
  border: 1px solid #807566;
}

@media screen and (min-width: 769px) {
  .col-news:hover {
    /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
    color: white;
    background-color: #655a4e;
    /* text-shadow: 0px -1px 1px #000000; */
  }


}

@media screen and (max-width: 768px) {
  .col-news:active {
    /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
    color: white;
    background-color: #655a4e;
    /* text-shadow: 0px -1px 1px #000000; */
  }
  .reviewPadding{
    padding-left: 0;
    padding-top: 0;
  }

  .footerIn {
    width: 90% !important
  }
}


.col-news:hover .head-news {
  text-shadow: none;
}



.single-card {
  width: 97% !important;
}

.view-news {
  background: transparent;
  /* padding-left: 75%; */
  /* margin-top: -2%; */
  text-align: right;
  font-size: 12px;
  cursor: pointer;
  margin-top: 2%;

}

.height-60px {
  height: 60px;
}

div.desc-news {
  white-space: nowrap;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* height: 100px; */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

div.desc-news1 {
  white-space: nowrap;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* height: 100px; */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

div.desc-sch {
  white-space: nowrap;
  width: 300px;
  overflow: hidden;
}

.desc-package {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  width: 90%;
}

.priceTags {
  font-family: NeueMd !important;
  font-weight: 900;
  font-size: 18.72px !important;
}

.head-news {
  font-family: NeueMd !important;
  background: transparent;
  /* padding-top: 2%; */
  font-weight: bolder;
  /* margin-top: -18%; */
  /* padding-left: 22%; */
  /* text-shadow: 0px -1px 1px #000000; */
  font-size: 24px !important;
}

.sch-history {
  display: flex;
}

.sch-status {
  padding-bottom: 5%;
}

.img-news {
  /* padding-top: 2%; */
  background: transparent;
  /* height: 110px; */
  /* width: 20%; */
  width: 13%;
  /* margin-bottom: -2%; */
  /*new styles*/
  object-fit: contain;
}

.footerIn {
  width: 60%;
  padding-bottom: 2%;
}





@media screen and (max-width: 1300px) {
  .col-news2 {
    width: 100% !important;
  }

  /* .head-news {
    padding-top: 2%;
  } */
}

@media screen and (max-width: 1000px) {
  .col-news {
    width: 100% !important;
  }

  .col-his {
    width: 97% !important;

  }

  .img-news {
    width: 20%;
  }
}

@media screen and (max-width: 700px) {
  .imgPlayButton {
    width: 110% !important;
  }
}


@media screen and (max-width: 510px) {
  img.logo {
    height: 40%;
    width: 50%;
  }



  .col-news {
    /* background: #807566; */
    /* background: linear-gradient(to bottom, #F9F5EC 5%, #F9F5EC 100%); */
    /* background-color: #F9F5EC; */
    /* width: 100% !important; */
    height: auto !important;
  }

  .col-his {
    background: #F9F5EC;
    width: 97% !important;
  }

  div.desc-news {
    width: 200px;
  }

  div.desc-sch {
    width: 200px;
  }

  .head-news {
    background: transparent;
    font-size: 20px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .desc-news {
    font-size: 10px;
  }

  .desc-sch {
    font-size: 10px;
  }

  .view-news {
    font-size: 10px;
  }

  .img-news {
    width: 35%;
  }

  .priceTags {
    font-size: 15px !important;
  }

  .descrptn_cont {
    display: flex;
    flex-direction: column;
    align-items: self-start;
  }
}