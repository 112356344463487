.loader{
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
   justify-content: center;
    z-index: 2000;
    background: #000000a6;
    /* padding-top: 40vh; */
}