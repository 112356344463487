.darkBrown{
  color: #655a4e;
}
div.news-desc1>blockquote>p>a,
div.news-desc1>p>a {
  color: #655a4e !important
}

.col-news:hover div.news-desc1>p>a,
.col-news:hover div.news-desc1>blockquote>p>a {
  color: #F9F5EC !important
}

.col-news:hover .view-news {
  /* background-color: #F9F5EC; */
  color: #F9F5EC
}

/* .col-news:hover .head-news {
  text-shadow: 0px -1px 1px #000000;
} */

.news-desc1 {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2%;
  margin-bottom: 5%;
}


.view-news {
  position: absolute;
  right: 3%;
  bottom: 0%;
  margin-top: 0%;
  /* color: white; */
  color: #655a4e;
  text-decoration: auto
}

img.thumb-image {
  padding-top: 5%;
  /* height: 80vh; */
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

div.sqs-image-shape-container-element.has-aspect-ratio {
  padding-bottom: 0px !important;
}

div.respNews>p>a {
  color: black
}

.mtClass {
  margin-top: 10px
}



.img-n {
  padding-top: 5%;
  /* height: 80vh; */
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.viewDetl {
  padding-left: 10%;
  font-size: 13px;
  color: gray;
  text-decoration: underline;
  cursor: pointer;
}

.news-m {
  /* background-color: #f9f4e7; */
  /* background-color: #F9F5EC; */
  background-color: #fffbf0;
  /* background-color: #FFFBF0; */
}

.news-dets {
  background-color: #FFFBF0;
}

.cont-n {
  padding-left: 10%;
  padding-top: 2%;
  padding-right: 8px;
}

.new-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2%;
  margin-bottom: 5%;
}

.date-price {
  font-size: 18.73px;
  line-height: 19px;
  opacity: 0.76;
  color: #655a4e;
}

/* span.planPrice {
  font-family: NeueMd !IMPORTANT;
  font-weight: normal;
} */

span.planPrice {
  color: black;
  opacity: 1 !important;
}

.news-h {
  /* font-family: NeueBold !important; */
  font-style: normal;
  font-weight: normal;
  margin-top: 2%;
  font-size: 24px;
  line-height: 27px;
  color: black !important;
}

.news-p {
  font-size: 16px;
  line-height: 19px;
  /* opacity: 0.7; */
  width: 90%;
  font-family: Neue !important;
  color: black !important;
}

.news-d {
  font-size: 16px;
  line-height: 16px;
  color: #8b7252;
}


/*

@media screen and (max-width: 848px) {
  .mtClass {
    margin-top: -50px;
  }
}

@media screen and (max-width: 360px) {
  .mtClass {
    margin-top: -37px;
  }
}

@media screen and (max-width: 452px) {
  .mtClass {
    margin-top: -50px;
  }
} */

@media screen and (max-width: 700px) {
  .img-n {
    padding-top: 5%;
    height: 70%;
    width: 85%;
  }

  .news-p {
    width: 90%;
  }

  .news-h {
    margin-top: 5%;
  }
}