/* .AttorneyName {
  font-family: NeueMd !important;
} */
.consultationHeading{
  font-size: 20px;
    font-weight: 400 !important;
    font-family: 'NeueBold' !important;
    font-style: normal;
    color: rgba(8,27,51,.8);
}
.font18{
  font-size: 18px;
}
.font16{
  font-size: 16px !important;
}
.consultationPara{
  font-size: 15px;
}
.lightBlack{
  color: rgba(8,27,51,.8);
}
.marginTop10{
  margin-top: 10px;
}


.SelectedConsultant {
  background-color: #807566;
  border: 2px solid #807566;
  padding: 10px;
  border-radius: 6px;
}

.googlePlaybtn {
  background-color: white;
}

.gpay-card-info-container {
  height: 50px !important;
  min-height: 50px !important;
  width: 230px !important;
  min-width: 230px !important;
  font-family: Neue !important;
  font-size: 14px !important;
}

.gpay-button.short,
.gpay-button.plain {
  height: 50px !important;
  min-height: 50px !important;
  width: 230px !important;
  min-width: 230px !important;
  font-family: Neue !important;
}

.btnGuest,
.btnGuest:focus {
  border: none;
}

div.checkSch>div.form-check>input.form-check-input {
  background-color: #807566;
  border: #807566;
  border-radius: 0px;
  height: 15px;
  width: 15px;
  margin-right: 5px;
  box-shadow: none;
}

div.checkSch>div.form-check>input.form-check-input:checked {
  background-color: #807566;
  border: #807566;
  border-radius: 0px;
  height: 15px;
  width: 15px;
  margin-right: 5px;
  box-shadow: none;
}

.activeImgAch {
  height: 25px;
  margin-top: 25px;
  margin-right: 5px;
}

.schRespDiv {
  display: flex;
  width: 100%;
}

.schBtn {
  display: flex;
  width: 100%;
}

.schContainer {
  margin-top: 5%;
  margin-bottom: 10%;
  border-radius: 15px;
  /* background-color: #e9e2ce; */
  background-color: #fffbf0;
}

.labelSch {
  padding-top: 2%;
  padding-bottom: 3%;
  border-bottom: 1px solid black;
}

.sch {
  /* margin-top: 5%; */
  margin-bottom: 2%;
  padding-top: 1%;
  background: #b0a79c82;
  border-radius: 9px;
}

.mr-10 {
  margin-right: 10px;
}

.marginTop20 {
  margin-top: 20px;
}

.width-230 {
  margin-left: 2% !important;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  justify-content: center;
}

.sch-c {
  padding-top: 18%;
}

.ipt-sch {
  background-color: transparent;
  border: none;
}

.sch.container {
  width: 98%;
  height: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 0;
}

.sch-p {
  background: transparent;
  width: 88%;
  text-align: center;
}

.btn-group {
  width: 100%;
  background: transparent;
  padding: 15px;
}

.btn.btn-sch {
  background-color: #c4beb782;
  display: flex;
  align-items: center;
  width: 50%;
}

.btn.btn-sch:hover {
  background-color: #a08c73c7;
}

.btn.btn-schu {
  background: black;
  color: white;
  width: 90%;
  height: 50px;
  margin-top: 2%;
  margin-bottom: 4%;
}

.btn.btn-schu:hover {
  color: white;
}

.nav-his {
  padding-top: 3%;
  padding-left: 8%;
  /* background-color: #fffaed; */
  /* background-color: #f9f4e7; */
  /* background-color: #F9F5EC; */
  background-color: #fffbf0;
  color: black !important;
}

.nav-tabs .nav-link.active {
  /* background-color: #fffaed; */
  background-color: #fffbf0;
  color: black;
}

.nav-link:hover {
  color: black;
}

.nav-link {
  color: black;
}

.opt {
  margin-bottom: 7%;
}

.div-sch {
  margin-top: 7%;
}

.dateLoader {
  margin-top: 5%;
  margin-left: 45%;
}

.btn.btnSch {
  margin-top: 5%;
  margin-bottom: 5%;
  background: #655a4e;
  color: white;
  display: flex;
  justify-content: center;
  width: 98%;
  border: 1px solid #655a4e;
}

.btn.btnSch:hover {
  color: #655a4e;
  border: 1px solid #655a4e;
  background-color: #F9F5EC;
}

@media screen and (max-width: 1300px) {
  /* .sch.container {
    width: 50%;
  } */

  .sch-c {
    padding-top: 25%;
  }
}

/* @media screen and (min-width: 1380px) and (max-width: 1450px){
  .cont-acc{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 60% !important;
  }
} */
/* and (min-width: 764px) */
@media screen and (max-width: 1270px) {
  .name-div {
    margin-top: 3%;
    display: grid;
    width: 98%;
    align-items: center;
    /* justify-content: flex-start; */
    /* background: red; */
    /* margin: 0 auto; */
    /* padding-left: calc(100vw - 85vw); */
  }

  /* .width-230 {
    width: 500px;
  } */
}

@media screen and (min-width: 1270px) {
  .justifyingContent {
    justify-content: center;
  }
}

@media screen and (min-width: 1350px) {
  .sch.container {
    width: 98%;
    margin-left: 0%;
  }

  .sch-c {
    padding-top: 20%;
  }

  .tableCenter {
    position: relative;
    left: -2%;
  }
}

@media screen and (max-width: 972px) {

  .btn-group {
    background: transparent;
    display: block;
    width: 100%;
    padding: 0 16px;
  }

  .btn.btn-sch {
    width: 100%;
  }

  .btn-sch {
    margin: 5px 0;
    width: 100%;
  }

  .sch-c {
    padding-top: 25%;
  }
}

@media screen and (max-width: 690px) {
  .cont-acc {
    width: 100% !important;
  }

  .name-div {
    margin-top: 6%;
    display: grid;
    width: 98%;
    align-items: center;
    /* justify-content: flex-start; */
    /* background: red; */
    /* margin: 0 auto; */
    padding-left: 0;
  }

  .cont-acc {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
  }

  .width-230 {
    width: 100% !important;
  }

  .sch-p {
    margin-top: 5%;
    width: 90%;
  }

  .width-230 {
    width: 309px;
  }

  .btn-group {
    background: transparent;
    display: block;
    width: 100%;
    padding: 0 16px 13px;
  }

  .btn-sch {
    width: 100%;
  }

  .btn.btn-schu {
    margin-bottom: 5%;
  }

  .ipt-sch {
    width: 70%;
    height: 100%;
  }

  .sch-c {
    padding-top: 25%;
  }
}

@media screen and (max-width: 455px) {
  .typeDiv {
    font-size: 12px;
  }

  .ques-foo {
    font-size: 14px;
  }
}

.react-datepicker__day--today {
  background-color: transparent !important;
}

.selected:hover {
  cursor: pointer;
}

.consult_img {
  width: 75px;
  height: 75px;
  border-radius: 5px;
}

.removeMargin {
  margin: 0;
  margin-bottom: 10px;
}

.immigrationNeed:focus-visible {
  outline: none;
}

.consulting {
  position: relative;
  top: 24px;
  text-align: center;
}