html {
  scroll-behavior: smooth;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
html,
body {
  overscroll-behavior-x: none;
}

h1,
h2,
h3 {
  font-family: NeueMd !important;
  font-weight: 100 !important;
}

em {
  font-family: NeueMd !important;
  font-weight: 100 !important;
}

h1>strong,
h2>strong,
h3>strong {
  font-family: NeueBold !important;
  font-weight: 100 !important;
  font-size: 24px;
}

div.opt>div>div>h3>strong {
  font-size: 18.72px !important;
}

h2>strong {
  font-size: 24px !important;
}

@font-face {
  font-family: "Segoe";
  src: url("../src/assets/fonts/SEGOEUIL.woff") format("woff");
}

/* @font-face {
  font-family: Nueue;
  src: url("../src/assets/fonts/NeueHaasDisplayThin.ttf") format("truetype");
} */

@font-face {
  font-family: Neue;
  src: url("../src/assets/fonts/NHaasGroteskTXPro-55Rg.otf") format("OpenType");
}

@font-face {
  font-family: NeueBold;
  src: url("../src/assets/fonts/NHaasGroteskTXPro-75Bd.otf") format("OpenType");
}

@font-face {
  font-family: NeueMd;
  src: url("../src/assets/fonts/NHaasGroteskTXPro-65Md.otf") format("OpenType");
}