

.signupLogo {
  height: 150px;
  width: 150px;
  display: flex;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 0rem;
}

.labelForm {
  font-size: 15px;
  color: #4d5050;
}

div.labelForm>input.form-check-input {
  background: #807566;
  border: 1px solid #807566;
  border-radius: 0px;
}

div.labelForm1>input.form-check-input {
  background: #F9F5EC;
  border: 3px solid #655a4e;
  border-radius: 0px;
  height: 5spx;
}

div.desclaimCheck>input.form-check-input:checked[type=checkbox] {
  background-color: #827564;
  border: 1px solid #827564;
}




.phoneCode.dropdown-toggle.btn.btn-success {
  width: auto;
  border: none !important;
  background: #F9F5EC;
  border-radius: 3px;
  color: black;
  padding-bottom: 2px;
  border-bottom: 2px solid grey !important;

  text-align: left;
  padding-left: 4px;
  padding-right: 0px;
}

.dropCode.dropdown-menu.show {
  overflow: scroll;
  height: 280px;
  /* background-color: #F9F4E7; */
  width: 400px
}

form.signin-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

input.signin-input {
  border: none;
  border-bottom: 2px solid grey;
  margin-bottom: 25px;
  margin-top: 10px;
  background: transparent;
}

.country-input {
  border: none;
  border-bottom: 2px solid grey;
  margin-bottom: 25px;
  margin-top: 10px;
  background: transparent;
}

.css-1s2u09g-control {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
}

label {
  opacity: 0.8;
  /* width: 85%; */
}

.btn.btn-auth {
  background-color: black;
  color: white;
  border-radius: 5px;
  margin-bottom: 5%;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  border: 1px solid black;
}

.btn.btn-guest,
.btn.btn-guest:hover {
  background: #655a4e;
  margin-top: -7px;
  color: white;
  border-radius: 5px;
  margin-bottom: 5%;
  /* margin-top: 5%; */
  display: flex;
  justify-content: center;
  width: 100%;
  border: none !important;
}

.btn.btn-guest1,
.btn.btn-guest1:hover {
  color: #655a4e;
  margin-top: -7px;
  border-radius: 5px;
  margin-bottom: 5%;
  /* margin-top: 5%; */
  display: flex;
  justify-content: center;
  width: 100%;
  border: 2px solid #655a4e !important;
}

.btn.btn-auth:hover {
  color: white;
}

.btn.btn-verify {
  width: 100%;
}

.container.auth-container {
  width: 40%;
}
@media screen and (max-width:768px) {
  .container.auth-container {
    width: 60%;
  }  
}


label.forget-pwd {
  font-size: 15px;
  color: #4d5050;
}

.links {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.links:hover {
  text-decoration: none;
  color: inherit;
}

.otp-paragraph {
  padding-top: 8%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  justify-content: center;
}

.otp-email {
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #716351;
}

.otp-input {
  width: 20%;
  height: 80px;
  border: none;
  border-radius: 10px;
  margin-left: 2px;
  background: #82756547;
  margin: 0 5px;
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  outline: none;
  color: black !important;
}



.otp-div {
  margin-left: 10%;
}

.enter-mail {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  padding: 2%;
  width: 100%;
  border: none;
  border-bottom: 2px solid black;
  background: none;
}

.mail-input {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 700px) {


  .container.auth-container {
    width: 80%;
  }
}