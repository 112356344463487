.reportPara {
  font-size: 14px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 4%;
  text-align: left;
  padding-left: 15px;
  padding-right: 10px;
}

.reportDivBtn {
  border: 2px solid black;
  background: none;
  border-radius: 15px;
  padding: 6px;
  width: 90%;
  text-align: left;
  font-family: NeueMd !important;
  font-size: 14px;
  margin-bottom: 2%;
  color: black
}

.reportDivBtn:hover {
  background-color: #655a4e;
  color: white;
  border: 2px solid transparent;
}

.reportDivBtn:hover svg.consultantIcon>g>path {
  fill: white !important;
}

.reportLang {
  font-family: NeueMd !important;
}

.reportDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-top: 4%;
}

.cardDiv {
  margin: 0 auto;
  border: 2px solid #655a4e;
  margin-top: 10px;
  height: 45px;
  width: auto
}

.cardHolder {
  background-color: #F9F5EC;
  border-bottom: 2px solid #655a4e !important;
  border: none;
  padding-left: 20px;
}

.modalGuestbtn,
.modalGuestbtn:hover {
  background: transparent;
  border: 2px solid #655a4e !important;
  color: #655a4e;
}

/* .modal-backdrop.fade.show {
  position: relative;
} */

.reloadBtn {
  margin-right: 0px !important;
}

button.reloadBtn>svg>g>path {
  fill: #655a4e
}

.ReportBtn,
.ReportBtn:hover {
  height: 50px;
  color: #807566;
  border: 2px solid #807566;
  background-color: #F9F5EC;
}

.modalPay {
  height: 50px;
  width: 48%;
  background-color: black;
}

.btnGuest:hover {
  background-color: #000000;
}

.mdl-footer {
  display: flex;
  justify-content: center;
  /* background: #e9e2cf; */
  background-color: #fffbf0;
  padding: 20px 0;
}

.sch-modal {
  margin-top: 2%;
  margin-bottom: 2%;
}

.btn-modal {
  background-color: black;
  color: white;
  width: 100%;
}

.btn-modal:hover {
  background-color: black;
  color: white;
}

.modal-title {
  color: black;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fffbf0;
  /* background: #e9e2cf; */
  border-bottom: 1px solid #aa9e8f;
  text-align: center;
}

.modal-body {
  /* background: #e9e2cf; */
  background: #fffbf0;
  color: black;
}

.modal-footer {
  /* background: #e9e2cf; */
  background: #fffbf0;
}

.form-check-input:checked {
  background-color: #000000;
  border-color: #000000;
  outline: none;
}

.form-check-input:focus {
  outline: none;
}

.modal-b {
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-w {
  width: 100% !important;
}

.check-m {
  margin-right: 10%;
}

.name-div {
  margin-top: 6%;
  display: grid;
  width: 98%;
  align-items: center;
}

.or {
  margin-top: 3%;
}


.ipt-s {
  /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
  /* background-color: #F9F5EC; */
  background-color: #fffbf0;
  border: 1px solid #655a4e !important;
  padding: 10px;
  border-radius: 6px;
  /* color: white; */
  color: #655a4e;
  font-family: NeueMd !important;
  /* text-shadow: 0px -1px 1px #000000; */
}

.consult-Name {
  font-family: NeueMd !important;
}

.ipt-active {
  /* background-color: #807566;
  border: 2px solid transparent;
  color: white;
  padding: 10px;
  border-radius: 6px; */
  border: 1.5px solid #655a4e;
  font-family: NeueMd !important;
  /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
  background-color: #655a4e;
  color: white;
  padding: 10px;
  border-radius: 6px;
  /* text-shadow: 0px -1px 1px #000000; */
}

.mr-body {
  display: flex;
  justify-content: center;
  text-align: center;
}

.mr-foot {
  display: flex;
  justify-content: center;
  border: none;
}

.btn-yes,
.btn-yes:focus,
.btn-yes:active {
  border: 1px solid black;
  padding: 10px;
  background-color: black;
  color: white;
  width: 35%;
}

.btn.btn-yes:focus {
  border: 1px solid black;
  padding: 10px;
  background-color: black;
  color: white;
  width: 35%;
}

.btn-yes:hover {
  color: white;
  background-color: black !important;
  border: 1px solid black !important;
}

.btn-no:hover {
  color: white;
  background-color: #655a4e !important;
  border: 1px solid #655a4e !important;
}

.btn-no,
.btn-no:focus,
.btn-no:active {
  padding: 10px;
  background-color: #655a4e;
  color: white;
  width: 35%;
  border: 1px solid #887965;
}

.btn.btn-no:focus {
  padding: 10px;
  background-color: #887965;
  color: white;
  width: 35%;
  border: 1px solid #887965;
}

.cont {
  margin-top: 30% !important;
}

.md-content {
  width: 80% !important;
}

.md-body {
  text-align: center;
  justify-content: center;
}

.md-p {
  color: #887965;
  font-size: 22px;
}

@media screen and (max-width: 700px) {
  .cont {
    margin-top: 50%;
  }
}

.applePay:disabled {
  background-color: #000000 !important;
  color: white;
  width: 100%;
}

.applePay:hover {
  /* opacity: 0.8; */
  background-color: rgba(1, 1, 1, 0.7);
}