.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #F9F5EC;
  color: #655a4e;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #F9F5EC;
  color: #655a4e;
}

button.Toastify__close-button.Toastify__close-button--colored>svg>path {
  fill: #655a4e
}