.ExpireInitial>.sc-bczRLJ.kEeNDb {
  width: 100% !important;
}

.paymentInitial>.sc-bczRLJ.kEeNDb {
  width: 100% !important;
}

.paymentIcon>path {
  fill: rgb(141, 128, 111) !important;
}

.labelCard {
  font-family: NeueBold !important;
  font-weight: 100 !important;
}

.paymentInitial>.dIRWMy {
  width: 100% !important;
}

.paymentInitial,
.ExpireInitial {
  text-align: end;
}

#field-wrapper.PaymentClass.is-invalid,
.PaymentClass {
  border: none !important;
  border-bottom: 2px solid grey !important;
  margin-bottom: 25px !important;
  margin-top: 10px !important;
  width: 100% !important;
}

div.paymentInitial>div.dIRWMy>p.hdGkUx {
  position: relative !important;
  margin-top: -21px;
}

div.PaymentClass>label>#card-number {
  width: 200px !important;
}

.PaymentClass>div.dIRWMy>p.hdGkUx {
  position: relative !important;
  margin-top: -21px;
}

div.PaymentClass>label>#card-expiry,
div.PaymentClass>label>#cvc {
  display: none;
}

/* ExpireInitial */

.ExpireClass>img {
  display: none;
}

.ExpireInitial>.dIRWMy {
  width: 100% !important;
}

.ExpireClass>label:nth-child(2) {
  display: none;
}

.ExpireClass>label:nth-child(3) {
  padding-bottom: 10px !important
}


.ExpireClass>label>#card-expiry {
  margin-left: -15px;
}

#field-wrapper.ExpireClass.is-invalid,
.ExpireClass {
  border: none !important;
  border-bottom: 2px solid grey !important;
  margin-bottom: 25px !important;
  margin-top: 10px !important;
  width: 100% !important;
}

div.ExpireInitial>div.dIRWMy>p.hdGkUx {
  position: relative !important;
  margin-top: -21px;
}

.paymentInitial>.sc-bczRLJ>.sc-eCYdqJ,
.ExpireInitial>.sc-bczRLJ>.sc-eCYdqJ {
  position: relative !important;
  margin-top: -21px;
}

/*".ExpireInitial >.sc-bczRLJ  >  .sc-eCYdqJ */
div.ExpireClass>label>#card-number {
  width: 200px !important;
}

.ExpireClass>div.dIRWMy>p.hdGkUx {
  position: relative !important;
  margin-top: -21px;
}

div.ExpireClass>label>#cvc,
div.ExpireClass>label>#card-number {
  display: none;
}


.cardAlreadyAdded {
  display: flex;
}

.cardAlreadyAdded>div.dIRWMy>div.ClassCardAdded>label>#card-expiry,
.cardAlreadyAdded>div.dIRWMy>div.ClassCardAdded>label>#cvc,
.cardAlreadyAdded>div.dIRWMy>p {
  display: none !important;
}

.cardAlreadyAdded>div.sc-bczRLJ>div.ClassCardAdded>label>#card-expiry,
.cardAlreadyAdded>div.sc-bczRLJ>div.ClassCardAdded>label>#cvc,
.cardAlreadyAdded>div.sc-bczRLJ>p {
  display: none !important;
}

.cardAlreadyAdded>div.dIRWMy>div.ClassCardAdded>label>#card-number {
  color: black;
}

#cardAdded {
  width: 100px !important;
  min-width: 10px !important;
  min-height: 10px !important;
  height: 30px !important;
}

div.ClassCardAdded {
  border: none !important;
}

.cardAlreadyAdded {
  width: 100%;
  align-items: center
}

.gpay-button-fill>.gpay-button.white,
.gpay-button-fill>.gpay-button.black {
  padding: 16px 10% 15px 10% !important;
}

svg.MenuIcon>path {
  fill: #655a4e;
}

.payMarginBottom {
  margin-bottom: 9px;
}

.modalPay.applePay {
  width: 100% !important;
}

.modalPay.applePay:focus {
  background-color: #000000;
  color: #faf6ec;
}

.gpay-button {
  height: 50px !important;
  width: 220px !important;
}

.gpay-button.black.short.en {
  width: 100% !important;
}

.google-pay-button-container.googlePlaybtn {
  width: 100% !important;
}

.line {
  border: 0.5px solid black;
  height: 1%;
  width: 33%;
  background-color: black;
}

.lineText {
  font-family: Neue !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: black;
  padding: 0% 3% 0% 3%;
}

.lineDiv {
  margin-top: 7%;
  margin-bottom: 7%;
  display: flex;
  flex-direction: row;
  height: 0%;
  align-items: center;
  justify-content: center;
}

a.dropItemsLang {
  color: black !important;
}

.selectTimeZone.form-select {
  background-color: #655a4e !important;
}

.heading {
  padding-top: 2%;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  justify-content: center;
}

.paragraph {
  background: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 40%;
  margin: 0 auto;
  margin-bottom: 3%;
}

.footer {
  /* background: #f9f5ec; */
  background: #fffbf0;
  ;
  display: flex;
  justify-content: center;
  max-width: 100% !important;
}

.foots {
  display: flex;
  margin-bottom: 2%;
}

.navigation {
  /* background: #dbc3a547; */
  /* background-color: #f0e9d9; */
  /* background-color: #f9f4e7; */
  /* background-color: #f9f5ec; */
  background-color: #fffbf0;
  display: flex;
  box-shadow: 2px 6px 4px -4px #dad2c2 !important;
  position: relative;
  margin-bottom: 10px;
}

.navi-paragraph {
  display: flex;
  align-items: center;
  padding: 5px;
  align-items: center;
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  background: transparent;
  padding-top: 10px;
}

.getstart {
  font-size: 0.9rem !important;
  border: 1px solid black !important;
}

.btn.btn-main {
  color: white;
  background: black;
  margin-top: 2%;
  margin-bottom: 2%;
  height: 50px;
  width: 100%;
}

.btn.btn-main:hover {
  color: white;
  border: 1px solid black;
}

.back {
  /* margin-left: 0.5%;
  margin-top: 0.2%; */
  display: flex;
  align-items: center;
}

.side {
  margin-top: 0 !important;
  /* color: black; */
  color: #655a4e !important;
}

@media screen and (max-width: 767px) {
  .btn.btn-main {
    width: 100%;
  }

  .packageBtn {
    margin-bottom: 5% !important;
  }
}

@media screen and (max-width: 700px) {
  .heading {
    width: 98%;
    font-size: 21px;
    text-align: center;
  }

  .paragraph {
    width: 100%;
  }

  .img-n {
    padding-top: 5%;
    height: 70%;
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .foots {
    margin-bottom: 5%;
  }
}