div.ModalPaid>div.modal-dialog>div.modal-content {
  width: 80%;
  margin: 0 auto
}

.spanModal {
  font-family: NeueBold !important;
}

.PaidModalBtn,
.PaidModalBtn:hover,
.PaidModalBtn:focus,
.PaidModalBtn:active {
  text-align: left !important;
  background: #655a4e !important;
  border: #655a4e !important;
  color: white !important;
  border-radius: 12px;
  padding: 15px
}

div.dropCode.dropdown-menu.show {
  background-color: #655a4e;
}

input.countryList {
  width: 100%;
  /* margin-left: 14px; */
  margin-bottom: 10px;
  border: none;
  border-bottom: 1px solid black;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-top: -10px;
  background: #F9F5EC;
}
.dropdownWidth{
  width:100%
}
.dropdownReviewWidth{
  width:97%
}
.dropdownContainer{
  display: flex;
  flex-direction: column;
  align-items: self-start;
}
div.country-input1>div.css-26l3qy-menu>div.css-4ljt47-MenuList {
  background: #655a4e !important;
  position: absolute;
  bottom: 50px;
  border: none;
  color: white !important;
}





div.country-input1>div.css-26l3qy-menu>div.css-11unzgr {
  background: white !important;
  position: absolute;
  bottom: 50px;
  border: none;
}



.css-26l3qy-menu {
  box-shadow: none !important;
}


div.country-input1>div.css-1pahdxg-control {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

div.country-input>div.css-1pahdxg-control {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

div.country-input1>div.css-1pahdxg-control:hover {
  border: none !important;
  cursor: pointer;
}

.country-input1 {
  border-bottom: 2px solid grey;
  margin-bottom: 25px;
  margin-top: 10px;
  background: transparent;
}

.react-tel-input .form-control {
  width: 100% !important;
  border: none !important;
  border-bottom: 2px solid grey !important;
  margin-bottom: 25px !important;
  margin-top: 10px !important;
  background: transparent !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.react-tel-input .selected-flag {
  background: #f9f4e7 !important;
  border-bottom: 2px solid grey !important;
  height: 35px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #fffaed;
}

.react-tel-input .flag-dropdown {
  border: none !important;
  /* border-bottom: 2px solid grey !important; */
  border-radius: 0px m !important;

  background: #f9f4e7;
}

.menu {
  margin-top: 0% !important;
}

.pyamentClose {
  width: 21px;
  background: #e9e2cf;
  height: 21px;
  text-align: center;
  border-radius: 16px;
  cursor: pointer;
}

.payCont {
  display: flex;
  justify-content: space-between;
}

.paymentCross {
  height: 8px;
  width: 8px;
  margin-bottom: 4px;
}

.acc {
  margin-left: -7%;
}

.cont-acc {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 70% !important;
}

.cont-acc1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 70% !important;
}

@media screen and (max-width: 1000px) {
  .cont-acc {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100% !important;
  }

  .cont-acc1 {
    width: 90% !important;
  }
}

@media screen and (max-width: 820px) {
  .cont-acc-after {
    width: auto !important;
  }
  .marginBottomSpaceOnTab{
    margin-bottom: 81px;
  }
  .dropdownWidth{
    width:103%
  }
  .dropdownReviewWidth{
    width:100%
  }
  .dropdownContainer{
    display: flex;
    flex-direction: column;
    align-items: self-start;
  }
}

.cont-acc-after {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}

.col-acc {
  padding-top: 5%;
}

ul.acc {
  display: flex;
  list-style: none;
}

ul.acc-after {
  display: flex;
  list-style: none;
  padding-top: 5%;
  justify-content: center;
}

ul.acc-after1 {
  display: flex;
  list-style: none;
  padding-top: 5%;
  margin-left: -34px;
}

.p-acc {
  font-style: normal;
  font-weight: bolder;
  font-size: 18px;
  line-height: 24px;
  margin-left: 10px;
}
.signUpPertitionEmailBold {
  font-style: normal;
  font-weight: bolder;
  font-size: 18px;
  line-height: 24px;
}


.acc-input {
  border: none;
  border-bottom: 2px solid grey;
  margin-bottom: 25px;
  margin-top: 10px;
  width: 88%;
  background: transparent;
}

.acc-payment {
  border: none;
  border-bottom: 2px solid grey;
  margin-bottom: 25px;
  margin-top: 10px;
  width: 100%;
  background: transparent;
}

.acc-input-after {
  border: none;
  border-bottom: 2px solid grey;
  margin-bottom: 25px;
  margin-top: 10px;
  width: 100%;
  background: transparent;
}

.acc-inpu {
  border: none;
  border-bottom: 2px solid grey;
  margin-bottom: 25px;
  margin-top: 10px;
  background: transparent;
}

.acc-inpu-after {
  border: none;
  border-bottom: 2px solid grey;
  margin-bottom: 25px;
  margin-top: 10px;
  width: 100%;
  background: transparent;
}

.navi-para {
  padding: 5px;
  align-items: center;
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  background: transparent;
}

@media screen and (max-width: 700px) {
  .col-acc {
    width: 100% !important;
  }

  .acc {
    display: flex;
    justify-content: center;
    margin-left: -20% !important;
  }

  .acc-inpu {
    width: 60%;
  }

  .navi-para {
    padding: 0px;
    margin-left: 17%;
    margin-top: 1.5%;
  }

  .p-acc {
    width: 100%;
  }
}

.accountCont {
  padding: 9px 14px;
  width: 286px;
  cursor: pointer;
}

.accountItem {
  padding-left: 10px;
  /* color: #827564; */
  color: black;
  font-size: 15px;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: url("../../../assets/images/dropdown.png") !important;
  border-top: none;
  /* border-bottom: 0; */
  /* border-left: 0.3em solid transparent; */
}

.drop-btn {
  border: none !important;
  background-color: transparent !important;
  color: black !important;
  /* color: #827564 !important; */
}

.dropdown-item {
  color: white !important;
  /* color: #827564 !important; */
}

div.dropdownPackage.show.dropdown>div>a {
  color: #655a4e !important
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #887c6b47 !important;
}


.lng_mrgn {
  margin-right: 6px;
  margin-bottom: 2px;
}

.menu-drop {
  background: #fffaed !important;
  box-shadow: 1px 1px 3px 1px #00000024 !important;
  margin-right: 10px;
  color: #827564 !important;
}

.homepage {
  /* background-color: #F9F5EC; */
  background-color: #fffbf0;
}

.vido {
  position: relative;
  left: -44px;
  width: 60%;
}

@media screen and (max-width:480px) {
  div.ModalPaid>div.modal-dialog>div.modal-content {
    width: 100%;
  }
}
.servicePara{
  color: #2e2e2e;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.02em;
}
.removeDecoration{
  font-family: NeueBold !important;
  font-weight: 100 !important;
  text-decoration: none;
    background-color: transparent;
}