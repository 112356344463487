li.sideactive>span>a>span>span {
  color: white !important;
  /* text-shadow: 0px -1px 1px #000000; */
}

.sideactive {
  /* background: linear-gradient(to bottom, #655a4e 5%, #807566 100%); */
  background-color: #655a4e;
}

span.items>span {
  font-family: NeueMd !important;
}

svg>rect {}

.nav-menu.active {
  box-shadow: 7px -2px 4px -4px #dad2c2 !important;
}

.sideBarlogos {
  width: 250px;
  height: 130px;
  margin-bottom: 25px;
  margin-top: 25px;
}

svg.sideBarlogos>g>path {
  fill: #807566 !important
}


a.menuText {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 400;
  /* margin-left: 13px;
  margin-top: 3px; */
}

.navbars {
  /* position: absolute; */
  background: transparent;
  /* right: 0; */
  /* margin-top: 2%; */
  /* margin-right: 10px; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: space-between; */
  /* padding-top: 0.3rem; */
  /* padding-bottom: 0.5rem; */
  /* left: 38px; */
  /* margin-left: 20px; */
}

.margin300 {
  margin-left: 253px;
}

.menu-bars {
  /* margin-left: 2rem; */
  font-size: 2rem;
  background: transparent;
}

.menu-bars2 {
  margin-left: 2rem;
  font-size: 2rem;
  background: transparent;
}

.side-menu {
  background: transparent;
  position: absolute;
  left: 0;
  margin-top: 23%;
  width: 16px;
  /* margin-right: 15px; */
  /* margin-top: -2px; */
  margin-left: 25%;
}

.nav-menu {
  /* border-right: 1px solid #c5bfb2; */
  width: 253px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 2;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 2;
  /* background: #f4eddd; */
  /* background-color: #F9F5EC; */
  background-color: #fffbf0;
}

.nav-text {
  color: black;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  /* font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center; */
  padding: 0 16px;
  border-radius: 4px;
  color: black;
}

.nav-text a:hover {
  color: black;
}

.nav-menu-items {
  /* background-color: #F9F5EC; */
  background-color: #fffbf0;
  width: 100%;
  padding-left: 0;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  margin-right: 8px;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span.items {
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

@media screen and (max-width: 700px) {
  .side-menu {
    margin-top: 24%;
    height: 95%;
    margin-left: 25%;
  }
}

@media screen and (max-width: 768px) {
  .footer-fix {
    /* box-shadow: 2px 5px 9px black; */
    position: fixed;
    bottom: 0;
    left: 0 !important;
    right: 0;
  }
  .padding {
    padding: 0 36px;
    padding-bottom: 199px !important;
    max-width: 1493px;
  }
}

.padding {
  padding: 0 36px;
  padding-bottom: 150px;
  max-width: 1493px;
}

.footer-fix {
  /* box-shadow: 2px 5px 9px black; */
  position: fixed;
  bottom: 0;
  left: 253px;
  right: 0;
}

.defaultSvg>path {
  fill: #FFFF !important;
  /* stroke: #887c6b47 !important; */
}

.defaultSvg1>path {
  fill: #827566 !important;
  margin-right: 10px;
  /* stroke: #887c6b47 !important; */
}

.defaultSvg>g>path {
  fill: #655a4e !important;
  /* stroke: #887c6b47 !important; */
}

svg.defaultSvg>g>clipPath>path {
  fill: #655a4e !important
}

svg.defaultSvg>g>g {
  fill: #655a4e !important
}

.lng_mrgn.defaultSvg>g>path {
  fill: black !important;
}

/* svg > path {
  fill : red;
}
svg > g > path {
  fill : red;
} */