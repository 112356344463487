.otpPaddingLeft {
  padding-left: 0px !important;
}

.sum-p>p {
  margin-left: 0px !important;
}

.textJustify {
  text-align: justify;
  padding: 15px;
  opacity: 0.5;
}

.agree {
  text-align: left;
  padding-left: 10px;
  position: relative;
  top: -8px;
}

.agreeLabel {
  opacity: 0.5;
  margin-left: 9px;
}

.scroll {
  height: 400px;
  overflow: scroll;
}

.headerSpac {
  margin-bottom: 23px;
}

.ReportClass {
  /* margin-top: 10px; */
  margin-left: 10px;
  cursor: pointer;
}

.btnReport,
.btnReport:focus,
.btnReport:active,
.btnReport:hover {
  background: black !important;
  border: 1px solid black !important;
  border-radius: 3px !important;
  width: 94% !important;
  margin: 0 auto !important;
  color: white !important;
  height: 45px;
}

.reportTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5%;
}

.reportSpan {

  font-size: 16px;
  font-weight: bolder;
  /* margin-top: 5%; */
  width: 75%;
}

.reportTextArea,
.reportTextArea:focus-within {
  border: 1px solid #d0c6b9;
  outline: none;
  background: #fffbf0;
  border-radius: 3px;
  height: 115px;
  margin-left: 10px;
  padding: 10px;
  width: 95%;
  margin-bottom: -15px;
}

div.modalReport>div.modal-dialog.modal-dialog-centered>div.modal-content {
  border-radius: 5px
}