/* .main {
  display: flex;
  flex-direction: column;
}

.main1 {
  display: flex;
  flex-direction: row;
  margin-left: 50px;
}
.section {
  background-color: #fffaec;
  padding-top: 20px;
  min-height: 100vh;
}

.label {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
}

.description {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 40px;
}

.btn {
  margin-top: 20px;
  margin-left: 200px;
} */
.layout {
  min-height: 100vh;
  background-color: #fffbf0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: scroll;
  /* min-height: 100vh;
  background-color: #F9F5EC;
  position: fixed;
  left: 0;
  right: 0; */
}

.main {
  /* background-color: #fffaed; */
  /* background-color: #F9F5EC; */
  background-color: #fffbf0;
}

strong {
  font-family: NeueBold !important;
  font-weight: 100 !important;
}



.mains {
  /* background-color: #fffaed; */
  /* background-color: #F9F5EC; */
  background-color: #fffbf0;
  /* position: absolute; */
  /* top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  /* background-color: #fffaed; */
  height: 100%;
}

input:focus {
  outline: none;
}

.btn:focus {
  box-shadow: none !important;
}

.resendBtn {
  border: none;
}

* {
  font-family: Neue !important;
  /* font-weight: bolder; */
  /* font-weight: normal !important; */
}

html {
  padding: env(safe-area-inset);
  background-color: #F9F5EC;
}

a {
  color: black !important
}

/* strong {
  letter-spacing: -2px !important;
} */

div.news-p>div {
  font-family: Neue !important;
}

div.news-p>span {
  font-family: Neue !important;
}

div.news-p>ul {
  font-family: Neue !important;
}
@media screen and (max-width: 820px) {
  .home_footerContainer{
    display: flex;
    flex-direction: column;
  }
  .home_footer_center{
    margin: 0 auto;
  }
  .hsrYVf{
    width: inherit !important;
  }
  .jRGTjS > *{
    flex: auto !important;
  }
  .jRGTjS{
    width:100% !important;
  }
  .revew-100{
    margin-top: 10px;
    width: 100%;
  }
}
.hsrYVf{
  width: inherit !important;
}
.jRGTjS > *{
  flex: auto !important;
}
.jRGTjS{
  width:100% !important;
}